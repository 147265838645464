/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
    --bs-btn-bg: #212529 !important;
    --bs-btn-border-color: #E1F0FF !important;
    --bs-btn-hover-color: #E1F0FF !important;
    --bs-btn-hover-bg: #525252 !important;
    --bs-btn-border-hover-color: #525252 !important;
    --bs-btn-active-color: #525252 !important;
    --bs-secondary-color: #667685 !important;
    --bs-dark-rgb: 18, 18, 18 !important;
    --bs-nav-link-font-size: 18px !important;
    --bs-nav-bar-color: #121212 !important;
    --bs-warning-rgb: 233, 70, 10 !important;
    --bs-box-shadow-sm: 5px 5px 15px rgba(0, 0, 0, 0.08) !important;
    --base0A: #6f42c1;
    --bd-violet: #712cf9;
    --bs-border-color-translucent: #E1F0FF !important;
    --bs-body-color: #001A33 !important;
    --swiper-theme-color: #000000 !important;
    --swiper-navigation-size: 24px !important;
    --bs-heading-color: #001A33 !important;

}

.h1,
h1,
.h2,
h2 {
    font-weight: 600;
}

.h1,
h1 {
    font-size: 2.7rem;
}

.h2,
h2 {
    font-size: 1.5rem;
}

.h3,
h3 {
    font-size: 1.25rem;
}

.h4,
h4 {
    font-size: 1rem;
}

.h5,
h5 {
    font-size: 0.9rem;
}

.h6,
h6 {
    font-size: 0.8rem;
}

.form-check-input:checked {
    background-color: #f9915b;
    border-color: #f9915b;
}

body {
    font-family: 'Inter', sans-serif;
}

.office-search .btn {
    --bs-btn-padding-y: 0.55rem;
}

.btn {
    --bs-btn-font-size: 0.86rem;
}

.form-control {
    font-size: 0.86rem;
}

.btn:hover svg path {
    fill: var(--bs-btn-hover-color)
}

.btn-light {
    --bs-btn-border-color: #E1F0FF;
    --bs-btn-hover-bg: #d3d4d5;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #E9460A;
    --bs-btn-border-color: #f9915b;;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #E14208;
    --bs-btn-hover-border-color: #E04006;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #E04006;
    --bs-btn-active-border-color: #DC3D03;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #E9460A;
    --bs-btn-disabled-border-color: #E9460A;
    background: linear-gradient(0deg, rgb(255 108 0 / 94%) 0%, rgb(255 139 91 / 91%) 100%);
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #001A33;
    --bs-btn-border-color: #001A33;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #001A33;
    --bs-btn-disabled-border-color: #001A33;
    background: linear-gradient(0deg, rgba(0, 26, 51, 1) 0%, rgba(6, 39, 71, 1) 100%);
}

.btn-group-lg>.btn,
.btn-lg {
    --bs-btn-padding-y: 0.7rem;
    --bs-btn-font-size: 1rem;
}

.start-80 {
    left: 80% !important;
}

.wn-mobile-top-bar {
    background-color: #fff;
    padding: 10px 0;
}

.office-search .form-control {
    height: 40px;
}

.required .col-form-label>span {
    position: relative;
}

.required .col-form-label>span:after {
    content: "*";
    position: absolute;
    top: 0.5px;
    right: -10px;
    font-size: 16px;
    color: red;
}

.office-search .form-control,
.pagination,
.pagination .form-control {
    font-size: 0.8rem;
}

/* paging */
.pagination {
    margin-top: 30px;
    margin-bottom: 30px;
}

.pagination .form-control {
    border-color: transparent
}

.pagination a svg {
    width: 12px;
    height: 12px;
}

/* swiper */
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #A3A5AD;
    opacity: .2;
    margin: 0 5px;
    border-radius: 20px;
    transition: opacity 0.5s, background-color 0.5s, width .5s;
    transition-delay: 0.5s, 0.5s, 0
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-theme-color);
    width: 43px;
    transition-delay: 0
}

/* .swiper-button-next,
.swiper-button-prev {
    padding: 24px;
    background: #fff;
    border-radius: 40px;
    box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.05);
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: -23px
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: -23px
} */

/**/
.pager {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pager a {
    padding: 0.3rem;
    width: 28px;
    height: 28px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001A33;
    margin: 0 0.2rem;
}

.pager a.current.disabled {
    background: #E9460A !important;
    color: #fff;
}

.pager a.disabled:hover {
    background-color: transparent;
}

.pager a:hover {
    background: #FFE1D6;
}

.wn-badget {
    width: 16px;
    height: 16px;
}

.bg-light-orange {
    background-color: #FEEDE7;
}

.pt-100 {
    padding-top: 100px
}

.pb-100 {
    padding-bottom: 100px
}

.pt-150 {
    padding-top: 150px
}

.pb-150 {
    padding-bottom: 150px
}

.py-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-30 {
    padding-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.min-w110 {
    min-width: 110px;
}

.w54 {
    width: 54px;
}

.h54 {
    height: 54px;
}

.w32 {
    width: 32px;
}

.h32 {
    height: 32px;
}

.top-header {
    border-bottom: 1px solid #001A33;
    font-size: 0.8rem;
    padding: 10px 0;
    background-color: #001A33;
}

#main-menu.wn-light-menu {
    background: #F9F9F9 !important;
}

.wn-have-video {
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    opacity: 0.1;
}

.wn-light-menu #main-nav .menu-item {
    display: contents;
}

.wn-light-menu #main-nav .menu-item .level-1 {
    color: var(--bs-body-color) !important;
}

/* .wn-light-menu .main-menu>li[data-program-id="ml.Home.About"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.Home.Brand"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.Products"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.Cibi"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.News"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.Sns"]>a:before,
.wn-light-menu .main-menu>li[data-program-id="ml.Cs-Service"]>a:before {
    content: "";
    width: 32px;
    height: 32px;
    background-image: url(./assets/img/icons/Icon_sprites.svg);
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto;
} */

/* .wn-light-menu .main-menu>li[data-program-id="ml.Home.About"]>a:before {
    background-position: 0 -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.Home.Brand"]>a:before {
    background-position: -33px -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.Products"]>a:before {
    background-position: -64px -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.Cibi"]>a:before {
    background-position: -95px -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.News"]>a:before {
    background-position: -125px -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.Sns"]>a:before {
    background-position: -162px -4px;
}

.wn-light-menu .main-menu>li[data-program-id="ml.Cs-Service"]>a:before {
    background-position: -203px -4px;
}

.wn-light-menu .main-menu>li.active[data-program-id="ml.Home.About"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.Home.Brand"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.Products"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.Cibi"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.News"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.Sns"]>a:before,
.wn-light-menu .main-menu>li.active[data-program-id="ml.Cs-Service"]>a:before {
    background-position-y: -31px;
} */

/**/
/* .wn-hero-page-heading{ min-height: 400px;} */
/**/
@media (min-width:576px) {
    .wn-thumb-img-cate {
        height: 73px !important;
    }

    .wn-grid>li:first-child .col-content {
        width: 100%;
    }

    .wn-grid>li:nth-child(2n) .wn-text {
        padding-right: 2rem;
    }

    .wn-grid>li:nth-child(3n) .wn-text {
        padding-left: 2rem;
    }

    .wn-grid>li:nth-child(2n) [class*="col-sm"] {
        order: 0
    }

    .wn-grid>li:nth-child(2n) .col-img {
        order: 1
    }

    /* .product-thumb { height:280px !important;} */
}

@media (min-width:768px) {
    .ms-4-md {
        margin-left: 1.5rem !important;
    }

    .card-hover-sm .wn-action-hover {
        margin-left: -60px;
        position: absolute;
        bottom: 0;
        left: 50%;
        opacity: 0;
        transform: translateY(100%);
        transition: transform 0.5s, opacity 0.5s;
    }

    .card-hover-sm:hover .wn-action-hover {
        opacity: 1;
        transform: translateY(-20%);
    }

    .sticky-top-md {
        position: sticky !important;
        top: 0;
        /* z-index: 1020; */
    }
}

@media(min-width:768px) and (max-width:991px) {}

@media (max-width:991px) {
    .position-fixed-xs {
        position: fixed !important;
        width: 100%;
        bottom: 0;
        left: 0;
        top: auto;
        z-index: 2;
    }

    .position-fixed-xs .wn-checkout-sum {
        padding: 0;
        background: none;
    }
}

@media (min-width:992px) {
    .wn-grid {
        grid-template-columns: 33.5% 65% !important;
    }

    .wn-grid>li:first-child .col-img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200px;
    }

    .section-relative-below {
        margin-top: -280px !important
    }

    .section-relative-below-content {
        padding-top: 50px !important;
    }

    .text-start-lg {
        text-align: left !important;
    }

    .px-lg-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    #product-content .tabs-nav {
        max-width: 480px;
        margin: 0 auto;
        border: 1px solid #E1F0FF;
        border-radius: 6px;
    }

    .h-lg-87 {
        min-height: 87px;
    }
}

.have-line {
    position: relative;
    display: inline;
}

.have-line:before {
    content: "";
    position: absolute;
    bottom: 3px;
    width: 100%;
    height: 6px;
    background-color: rgba(233, 70, 10, 0.2);
}

.wn-rounded-slider .swiper {
    border-radius: 20px;
}

.wn-grid {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 20px;
    row-gap: 20px;
    justify-items: stretch;
}

.wn-grid>li {
    border-radius: 20px;
}

.wn-grid>li .wn-text {
    margin-bottom: 20px;
}

.wn-grid>li:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
    background-color: #FEEDE7;
    position: relative;
}

.wn-grid>li:nth-child(2n) {
    background-color: #E1F0FF;
}

.wn-grid>li:nth-child(3n) {
    background-color: #F2FBF2
}

/**/
.btn-hover-01 {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit
}

.btn-hover-01.learn-more {
    width: 12rem;
    height: auto
}

.btn-hover-01.learn-more .circle {
    transition: all .45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 1.625rem
}

.btn-hover-01.learn-more .circle .icon {
    transition: all .45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff
}

.btn-hover-01.learn-more .circle .icon.arrow {
    transition: all .45s cubic-bezier(0.65, 0, 0.076, 1);
    left: .625rem;
    width: 1.125rem;
    height: .125rem;
    background: none
}

.btn-hover-01.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -.25rem;
    right: .0625rem;
    width: .625rem;
    height: .625rem;
    border-top: .125rem solid rgba(233, 70, 10, 1);
    border-right: .125rem solid rgba(233, 70, 10, 1);
    transform: rotate(45deg)
}

.btn-hover-01.learn-more .button-text {
    transition: all .45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: .75rem 0;
    margin: 0 0 0 .85rem;
    color: #001A33;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase
}

.btn-hover-01:hover .circle {
    width: 100%
}

.btn-hover-01:hover .circle .icon.arrow {
    background: rgba(233, 70, 10, 1);
    transform: translate(1rem, 0)
}

.btn-hover-01:hover .button-text {
    color: #001A33
}

/**/
.section-relative-top,
.section-relative-below {
    position: relative
}

.section-relative-top {
    z-index: 1;
    padding-bottom: 200px;
}

.section-relative-below {
    margin-bottom: 60px;
    margin-top: -200px;
    z-index: 2;
}

.section-relative-below-content {
    padding-top: 20px;
    padding-bottom: 20px
}

/**/
.hr_scrll-menu {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scrollbar-width: thin;
}

.hr_scrll-menu::-webkit-scrollbar {
    height: 1px;
}

.hr_scrll-menu>li:first-child {
    padding-left: 0;
}

/**/
.product-item .price {
    color: var(--bs-heading-color);
}

.product-item .point {
    color: var(--bs-secondary-color)
}

.wn-thumb-img-cate {
    object-position: center;
    object-fit: contain;
    height: 34px;
    margin-bottom: 10px;
}

/**/
#product-content .tabs-nav:before {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -40px;
    z-index: -1;
    width: 86px;
    height: 100px;
    background: url(./assets/img/cream_bg-detail.png) no-repeat center center;
}

.wn-input-qty {
    width: 110px;
}

.wn-checkout-sum .card {
    --bs-card-border-color: #F1D8CF;
    box-shadow: 0 0 0px 10px rgba(254, 237, 231, 1);
}

.wn-help-collapse:hover {
    cursor: pointer;
}

.wn-help-collapse:before {
    content: "";
    display: flex;
    background: url(./assets/img/close_arrow-icon.svg) no-repeat 0 6px;
    width: 16px;
    height: 16px;
}

.wn-help-collapse.collapsed:before {
    content: "";
    background: url(./assets/img/info-icon.svg) no-repeat 0 2px;
}

/**/
.reviewSlider {
    border-top: 1px solid var(--bs-btn-border-color);
    border-bottom: 1px solid var(--bs-btn-border-color);
}

/**/
.icon-shape {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
}

.icon-xxl {
    height: 2rem;
    line-height: 2rem;
    width: 2rem;
}

/**/
.footer-top {
    padding: 1.25rem 0;
}

.footer-top .list-inline-item:not(:last-child) {
    padding-right: .5rem;
    border-right: 1px solid white;
}

#footer-body .list-item-body:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, .5) !important;
}

#footer-body .list-item-body {
    padding-left: .5rem;
	padding-right: .5rem;
}

/**/
.bounce {
    animation: bounce 2s ease infinite;
}

.bounce2 {
    animation: bounce2 2s ease infinite;
}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }

    80% {
        transform: translateY(-15%);
    }

    90% {
        transform: translateY(0%);
    }

    95% {
        transform: translateY(-7%);
    }

    97% {
        transform: translateY(0%);
    }

    99% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}
